.Game {
    display:flex;
    flex-direction: row;
}

.Game .controls,
.Game .debug {
    flex-grow: 0;
    flex-shrink: 0;
    width: 70px;
    padding: 4px;
    background-color: black;
}

.Game .debug,
.Game .debug button {
    font-size: x-small;
    text-align: left;
}

.Game .controls,
.Game .breadcrumbs button,
.Game .debug,
.Game .debug button {
    color: white;
}

.Game .cardinfo {
    min-height: 3em;
}

.Game .breadcrumbs-header {
    margin-top: 0.5em;
}

.Game .breadcrumbs .breadcrumb.selected button {
    color: plum;
}

.Game .breadcrumb:last-child {
    background: darkgreen;
}

.Game .controls {
    font-size: x-large;
    display: flex;
    flex-direction: column;
}

.Game .controls button {
    padding: 10px 0;
}

.Game .breadcrumbs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Game .controls button {
    color: white;
}

.Game .debug button {
    font-size: x-small;
}

.Game .controls>button:disabled {
    color: gray;
}

.Game>.GameArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: black;
}

.Game .meters {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 84px;
}

.Game .meters .meter {
    background-color: white;
}

.Game .meters .icon {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
.Game .meters .icon img {
    width: 64px;
    height: 64px;
}

.Game .badge {
    width: 64px;
    height: 64px;
}

.GameCard {
    font-size: large;
    min-height: 8em;
}

.GameCard .IconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.GameCard .IconContainer .icon {
    height: 64px;
    width: 64px;
    padding: 4px;
}

.GameCard {
    box-shadow: 2px 3px 1px;
}

.GameOn {
    background-color: whitesmoke;
    box-shadow: 2px 3px 1px lightgray;
}

.GameReady {
    background-color: #454;
    box-shadow: 2px 3px 1px #232;
}

.GameOver {
    background-color: darkgray;
    box-shadow: 2px 3px 1px #444;
}

.GameSwipers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    background-color: black;
}

.GameSwiper, .GameTitle, .GameSubtitle {
    padding: 20px;
    margin: 2px;
}

.GameSwiper {
    border-style: solid;
    background-color: whitesmoke;
}

.GameTitle, .GameSubtitle {
    color: white;
}

.GameTitle {
    font-size: x-large;
    font-weight: bold;
}

.GameSubtitle {
    font-style: italic;
    height: 34px;
}

.GameSwiper>.preview {
    font-size: small;
    font-style: italic;
}

.GameStatusButton {
    border-style: solid;
    padding: 10px;
    margin: 20px;

    background-color: whitesmoke;
}
