.Meter {
  display: flex;
  flex-direction: column;

  border-style: solid;
  border-radius: 4px;
  margin: 2px;
  padding: 1px;

  font-size: x-small;
}

.Meter .name,
.Meter .init,
.Meter .range {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Meter .controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.Meter .metercontrols {
  text-align: center;
}

.Meter .isbadge {
  text-align: left;
}