.Macro {
  display: flex;
  flex-direction: column;

  border-style: solid;
  border-radius: 4px;
  margin: 2px;
  padding: 1px;

  font-size: x-small;
}

.Macro .header,
.Macro .name,
.Macro .text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Macro .controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Macro>.text>textarea {
    resize: vertical;
}