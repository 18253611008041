.Card, .GameCard {
  position: relative;

  display: flex;
  flex-direction: column;

  border-style: solid;
  border-radius: 4px;
  margin: 2px;
  padding: 1px;

  background-color: #f8f8ec;
  margin-bottom: 8px;
}

.Card {
  box-shadow: 2px 3px 1px #882;
}

.Card .html textarea.unedited {
  background-color: #ffffdc;
}

.Card>.dealer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Card .iconcontrols {
  min-height: 27px;
}

.Card .iconPanel,
.Card .dealer .meta,
.Card .Swiper {
  width: 309px;   /* hack */
}

.Card .iconPanel {
  background-color: #d9d9ce;
}

.Card .dealer .meta {
  display: flex;
  flex-direction: column;
}

.Card .dealer .meta .id {
  font-size: x-small;
  font-style: italic;
  text-align: right;
  padding-right: 2px;
  height: 27px;
}

.Card .eval {
  font-size: x-small;
  font-style: italic;
}

.Card .repeat-conditions .label {
  font-size: x-small;
}

.Card .rank-and-type-conditions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Card .rank-conditions,
.Card .type-conditions
 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.Card .rank-conditions div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Card .type-conditions .when {
  display: flex;
  flex-direction: row;
}

.Card .repeat-conditions {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 4px;
}

.Card .LimitingInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Card .LimitingInput div {
  display: flex;
  flex-direction: row;
}

.Card .LimitingInput button {
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: x-small;
  padding-bottom: 2px;
}

.Card .LimitingInput div label,
.Card .LimitingInput div span {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Card .addConstraint {
  margin: 8px 0px 8px 2px;
}

.Card>.player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Card .player .Swiper .stage {
  min-height: 22px;
}


.Card .cardmacro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 0px;
}
.Card .cardmacro .header {
  display: flex;
  flex-direction: row;
}
.Card .cardmacro .header button {
  font-size: x-small;
}
.Card .cardmacro .field {
  font-size: x-small;
}

.Card .cardmacro textarea {
  resize: vertical;
}

.Card>.cardcontrols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Card .html {
  margin-bottom: 4px;
}

.Card>.dealer>.html>textarea {
  resize: vertical;
  min-height: 4em;
}

.Card .thinking {
  font-size: small;
  font-style: italic;
}

.Card .player .mirror label,
.Card .swap button {
  font-size: x-small;
}

.Card .swap {
  height: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Card .swap>button {
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 2px;

  background-color: white;
}

.Card .swipecontrols {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Card .selected input {
  background-color: purple;
  color: white;
}

.App .Card.selected {
  border-color: purple;
  box-shadow: 2px 3px 8px purple;
}

.Card.ineligible {
  background-color: #fee;
}

.Card.eligible {
  background-color: #efe;
}

.Card.played {
  background-color: #dfd;
}

.Card span.eligible {
  color: green;
}

.Card span.ineligible {
  color: red;
}

.Card div:has(>label.eligible),
.Card .type {
  margin: 2px;
}

.Card .repeat-conditions label.eligible {
  margin-right: 2px;
}

.Card label.ineligible input {
  border-color: red;
  border-width: 4px;
  border-radius: 4px;
}