
.StageMap .react-flow__edge.selected path {
  stroke: purple;
  stroke-width: 2px;
}

.StageMapNode,
.TextUpdaterNode {
  color: #222;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  box-shadow: 1px 1.5px 1px gray;
}

.StartNode {
  border-width: 2px;
  border-radius: 10px;  
}

.App .NodeHasNoCards {
  font-weight: bold;
  background-color: #fee;
  color: darkred;
}

.App .StageMap .NodeHasNoIncomingEdges {
  font-weight: bold;
  background-color: #fcc;
  color: darkred;
}

.App .StageMap .SelectedHandle {
  background-color: purple;
  width: 10px;
  height: 10px;
}

.App .StageMap .SelectedHandle.source {
  bottom: -6px;
}

.App .StageMap .SelectedHandle.target {
  top: -6px;
}

.StageMap .DefaultHandle {
  background-color: black;
  width: 6px;
  height: 6px;
}

.StageMap .LooseEndHandle {
  background-color: #f22;
}

.App .StageMap .LooseEndHandle {
  border-radius: 0px;
}

.StageMapNode,
.TextUpdaterNode {
  font-size: 12px;
  border-radius: 3px;
  border-color: #1a192b;
}

.StageMapNode {
  padding: 10px;
}

.UnselectedStageMapNode {
  background-color: white;
}

.App .StageMap .TextUpdaterNode,
.App .StageMap .SelectedStageMapNode {
  background-color: purple;
  color: white;
}

.TextUpdaterNode {
  padding: 7px;
}

.TextUpdaterNode input {
  text-align: center;
}

.StageMap .cardcount {
  position: absolute;
  bottom: -1px;
  right: -1px;
  font-size: x-small;
  font-style: normal;
  font-weight: normal;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
 
  color: black;
  background-color: #f8f8ec;
  border-color: rgba(0,0,0,64);
  box-shadow: 2px 3px 1px #882;
}

.StageMap .edgecardcount {
  position: absolute;
  font-size: x-small;
  font-style: normal;
  font-weight: normal;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  padding: 1px;

  color: black;
  background-color: #f8f8ec;
  border-color: rgba(0,0,0,64);
  box-shadow: 2px 3px 1px #882;
}