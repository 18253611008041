.App {
  height: 100%;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.App .column1, .App .column2 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App .column1 {
  width: 720px;
  border-right: 1px solid black;
}

.App .column2 {
  flex-grow: 1;
}

.App .react-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}

.App .react-tabs>div {
  flex-grow: 1;  
  overflow-y: auto;
}

.App .react-tabs ul {
  text-align: left;
}

.App .react-tabs .scrollable {
  overflow-y: scroll;
}

.App .cards, .App .macros, .App .decks, .App .meters {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.App .macros .label {
  font-size: small;
}

.App .prompt-filter {
  font-size: small;
  padding-left: 1em;
}

.App .cardcount {
  flex-grow: 1;
  text-align: right;
}

.App .cardcount,
.App .notshown {
  font-size: small;
  font-style: italic;  
}

.App .morecards {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.App .morecards>button {
  text-align: right;
  font-style: italic;
}

.App .meters,
.App .macros,
.App .cards {
  position: relative;
}

.App .react-tabs>ul {
  position: relative;
}

.App .undocontrols,
.App .authcontrols {
  font-size: small;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;  
}

.App .meters>.controls,
.App .macros>.controls,
.App .cards>.controls {
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.App .meterlist {
  flex-wrap: wrap;
}

.App .meterlist .Meter {
  width: 147px;
}

.App .cards>.controls {
  border-bottom: 1px solid black;
}

.App .undocontrols button {
  padding-left: 4px;
}

.App .status>.text {
  display:flex;
  flex-direction: left;
  justify-content:flex-start;
}

.App textarea {
  resize: none;
}

.App .iocontrols {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

.App .iocontrols .titlecontrols {
  display: flex;
  flex-direction: row;
}

.App .iocontrols button {
  padding: 10px;
  font-size: large;
}

.App .filter {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}

.App .filterhelp,
.App .pagination {
  margin-top: 1em;
}

.App .hidefilter {
  margin-top: .5em;
}

.App .hidefilter label {
  font-size: medium;
}

.App .filterhelp,
.App .pagination,
.App .gamefilter,
.App .hidefilter,
.App .bypass {
  text-align: left;
}

.App .filter>div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.App .filter .typefilter, .App .filter .bypass {
  display: flex;
  flex-direction: column;
}

.App .filter .types {
  display: block;
  text-align: left;
}
.App .filter .types>label
 {
  padding-right: 4px;
}

.App .filter .typebuttons {
  display: flex;
  flex-direction: column;
}

.App .filter .typebuttons>div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.App .filter .typebuttons>div>button {
  text-align: left;
}

.App .bypass {
  margin-top: .5em;
}

.App .bypass-list {
  font-size: small;
}

.App .bypass-list span {
  padding-right: 1em;
}

.App .decklist table {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  margin: 1px;
  text-align: left;
}

.App .deckrow {
  padding: 10px;
}

.App .deckrow:nth-child(even) {
  background: lightgray;
}

.App .decklist .deckrow.selected {
  background: pink;
  color: white;
}

.App .deckrow>button {
  font-size: large;
}

.App .timestamp {
  color: black;
  font-style: italic;
  font-size: xx-small;
}

.App .expansion {
  font-size: small;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  label, button, a {
    font-size: small;
  }

  label:has(input:disabled), .label.disabled {
    color: gray;
  }
