
.IconSelector {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
  
.IconSelector .controls {
    display: flex;
    flex-direction: row;
}

.IconSelector .iconData {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.IconSelector .preview {
    width: 64px;
    height: 64px;
}

.IconSelector .iconPanel {
    max-height: 96px;
    overflow-y: scroll;
}

.IconSelector .iconPanel .iconButton {
    width: 32px;
    height: 32px;
}