.Swiper {
  display: flex;
  flex-direction: column;
}

.Swiper>div {
  display: flex;
  flex-direction: row;
}

.Swiper>.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Swiper>.header>.label {
  display: none;
}

.Swiper .rewards {
  display: flex;
  flex-direction: column;
}

.Swiper .rewards .reward {
  display: flex;
  flex-direction: row;
}